body {
  font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: #fff;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;
}

/* Top Navbar */
#header .navbar-blue, .dropdown-menu {
  background-color: #1b0088;
}

.dropdown-menu {
  outline: none;
  border: 0px;
}

#header .navbar-nav,
#header .navbar a,
#header .nav-item,
#header .nav-link {
  color: #fff;
  font-size: 12px;
}
.btn_level_1 {
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #ed1650;
  color: #fff;
  font-family: "LATAM Sans Regular";
  -webkit-transition: 0.3s;
  transition: 0.3s;
  outline: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  &:hover,
  &:focus {
    background-color: #f53859;
    color: #fff;
    -webkit-box-shadow: 0px 5px 7px 0px rgba(184, 184, 184, 1);
    box-shadow: 0px 5px 7px 0px rgba(184, 184, 184, 1);
  }

  &:focus {
    border: 2px solid #1b0088;
  }

  &:disabled {
    color: #858585;
    background-color: #e1e1e1;
    cursor: not-allowed;
  }
}
#header .navbar a:link{
  background-color: #1b0088;
}

#header .navbar a:hover{
  background-color: #1b0088;
  text-decoration: underline;
}

#header .navbar a:active{
  background-color: #1b0088;
  text-decoration: underline;
}

#header .navbar a:visited{
  background-color: #1b0088;
}


#header .logo-latam-header {
  width: 100%;
  height: auto;
}

#header a .nav-brand {
  width: auto;
}

#header .custom-toggler.navbar-toggler {
  border-color: none;
}
#header .custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/* Main Wrapper DIV */
.container-fluid {
  height: calc(100vh - 100px);
}

/* Footer */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #1b0088;
  color: #fff;
}

.footer p {
  font-size: 0.8em;
}

/* Overwrite */
.btn-group-xs > .btn, .btn-xs {
  padding  : .45rem .65rem;
  font-size  : .875rem;
  line-height  : .5;
  border-radius : .2rem;
}

.nav-tabs > li >a {
  color: #a7b1c2;
  font-weight: 600;
  padding: 10px 20px 10px 25px;
}

.wrapper-content {
  padding: 20px 10px 40px;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #fff;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 90px 8px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: none;
}

.ibox-content {
  background-color: #fff;
  color: inherit;
  padding: 15px 20px 20px;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
  clear: both;
}

.ibox-title .label {
  margin-left: 4px;
}
.ibox .label, .nav .label {
  font-size: 10px;
}

.badge-primary, .label-primary {
  background-color: #1ab394;
  color: #fff;
}
.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
  border-radius: .25em;
  line-height: 1;
  white-space: nowrap;
}

.text-navy {
  color: #1ab394!important;
}

.text-info {
  color: #23c6c8!important;
}

h1 {
  font-size: 30px;
}

.no-margins {
  margin: 0!important;
}

.font-bold {
  font-weight: 600;
}

.ibox-content h1, .ibox-content h2, .ibox-content h3, .ibox-content h4, .ibox-content h5, .ibox-title h1, .ibox-title h2, .ibox-title h3, .ibox-title h4, .ibox-title h5 {
  margin-top: 5px;
}
h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}
h4 {
  font-size: 14px;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 100;
}
.h4, h4 {
  font-size: 1.5rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}
*, ::after, ::before {
  box-sizing: border-box;
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import '../node_modules/bootstrap/scss/bootstrap';
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.container {
	background-color: rgb(255, 255, 255);
	padding: 0px;
	display: block;
	flex-direction: column;
	height: auto;
	min-height: 100vh;
  }

.app_card
{
	padding: 20px;
	margin-left: auto;
	margin-right:auto;	
}
  
.app_button
{
	background: rgb( 232, 15, 75 ) !important;
	color: white !important;
	font-size: medium;
	z-index: 0;
}

.app_footer
{
	text-align:center;
	width:100%; 
	position:absolute; 
	bottom: 0; 
	padding-top: 10px;
	padding-bottom: 10px;
	background: rgb( 232, 15, 75 );
	color: white;
	vertical-align: middle;
}
